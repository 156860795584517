import React from 'react'
import Styles from '../../../Assets/Styles/Style'
import FooterBg from '../../../Assets/Images/FooterLogo.png';
import S3 from '../../../Assets/Images/S1.png';
import FacebookL from '../../../Assets/Images/faceboook.png';
import WhatsappL from '../../../Assets/Images/whatsapp.png';
import InstagramL from '../../../Assets/Images/instagram.png';
import TwitterL from '../../../Assets/Images/Twitter.png';
function ContactSend() {
  return ( 
    <div className='container-fluid m-0 p-4'
    style={{minHeight:'50vh',backgroundOrigin:'border-box',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url(${FooterBg})`}}
     >
     <div className='row d-flex justify-content-center mt-5 m-0 p-2  p-md-5'>
      <div className=' col-12 col-md-5   m-0 px-md-5 px-1  p-0 d-flex flex-column'>
         <h5 className='fw-normal mt-4' style={{fontSize:'1rem'}}>
             JOIN OUR NEWSLETTER
         </h5>
         <h2 className='fw-bold mb-4' style={{fontSize:'2.25rem'}}>
              Contact us
         </h2>
         {/* <h2 style={{fontWeight:'bold'}}>Contact Us</h2> */}
      <h5>Where to Find Us</h5>
      <p>FS House, Opposite Street No 04, Main Road, Gangaal West, Shaheen Town, Rawalpindi, Pakistan.</p>
      <h5>Email Us At</h5>

      <a href="mailto:info@mpl-labs.pk">info@fssoft.pk</a>
      <h5>Call Us At</h5>
      <a href="tel:051111988988">051-111-988-988</a>
         {/* <h2 className='fw-bold mb-4' style={{fontSize:'2.25rem'}}>
              Contact us
         </h2>
         <p className='m-0 p-0 fs-5' style={{fontWeight:'600'}}>3045 10 Sunrize Avenue, 123-456-7890</p>
         <p className='m-0 p-0 fs-5' style={{fontWeight:'600'}}>Mon – Fri: 9:00 am – 8:00 pm,</p>
         <p className='m-0 p-0 fs-5' style={{fontWeight:'600'}}>Sat – Sun: 9:00 am – 10 pm</p>
         <p className='mb-md-5 mb-0  fs-5' style={{fontWeight:'600'}}>FsSoft@gmail.com</p> */}
         {/* <h2 className='fw-bold mt-1 pt-4' style={{fontSize:'2.25rem'}}>
              Follow us
         </h2>
         <p className=' mt-3 mb-3 m-0  d-flex flex-row'>
          <img className='img-fluid m-0 me-2 p-0  ' src={`${WhatsappL}`} style={{width:'35px',height:'35px'}}></img>
          <img className='img-fluid m-0 ms-2 me-2 p-0' src={`${InstagramL}`} style={{width:'35px',height:'35px'}}></img>
          <img className='img-fluid m-0 ms-2 me-2 p-0' src={`${FacebookL}`} style={{width:'35px',height:'35px'}}></img>
          <img className='img-fluid m-0 ms-2 p-0' src={`${TwitterL}`} style={{width:'35px',height:'35px'}}></img>
         </p> */}
         {/* <p className='mb-5 fs-5' style={{fontWeight:'600'}}>laravelprodev@gmail.com</p> */}
      </div>
      {/* <div className='col-md-7 col-12 m-0 px-md-5 px-0 p-0'>
         <div className='row m-0 p-0'>
            <div className='d-flex flex-column col-12 px-md-5 px-2 py-md-5 py-2 ' style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius}}>
                 <input className='mb-3 fs-5  p-md-3 p-2' placeholder='Enter your Name' style={{ border:'none',borderBottom:'2px solid black',backgroundColor:'transparent'}}></input>
                 <input className='mb-3 fs-5  p-md-3 p-2 px-1' placeholder='Enter a valid email address' style={{ border:'none',borderBottom:'2px solid black',backgroundColor:'transparent'}}></input>
                 <textarea className='  fs-5 p-md-3 p-1 px-md-3 px-1' placeholder='Enter your Message' style={{border:'none',minHeight:'22vh',borderBottom:'2px solid black',backgroundColor:'transparent'}}></textarea>
                 <button className='w-25 mt-5 mb-2 p-2 border-none' style={{ borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius, backgroundColor:Styles.brandReadMoreBtnColor}}>
                    SUBMIT
                 </button>
                 <p>Some random text</p>
            </div>
         </div>
      </div>   */}
     </div>
     <h5 className='fw-normal ' style={{fontSize:'1rem',alignSelf:"center",justifyContent:"center",marginLeft:window.innerWidth>500?"34%":"15%"}}>
    Copyright  ©  FS Soft 2023 
         </h5>
   </div>
  )
}

export default ContactSend