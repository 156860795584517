import React from 'react'
import Homee from '../../Components/Public/Home/Homee'
const HomeScreen=()=> {
  return (
    <div className='conatiner-fluid m-0 p-0'>
        <div className='row m-0 p-0'>
          <Homee></Homee>
        </div>
    </div>
  )
}

export default HomeScreen