import React from 'react'
import Styles from '../../../Assets/Styles/Style'
const Stratagies=()=> {
  return (
    <div className='d-flex flex-column pb-3' style={{backgroundColor:Styles.siteStrategiesBackgroundColor,minHeight:'85vh'}}>
    <div className='d-flex flex-column flex-md-row py-2 py-md-5 '>
      <div data-aos={"fade-up"} className=' w-50 d-flex align-item-center justify-content-center stratigy2 '>
        <h1 style={{fontSize:Styles.strategyHeading,marginLeft:'-5%',marginTop:"10%"}} className='fw-bolder' >About  Us</h1>
      </div>
      <div data-aos={"fade-up"} className='w-50 stratigy2'>
        <p className='stratigy2text' style={{fontSize:Styles.brandText ,marginRight:window.innerWidth>500?'25%':"5%" }}>
        FS Soft a dynamic Healthcare IT solution company was established in 2016 when the in-house
need and market demand was soaring . We have developed state of the art Laboratory
information & Management systems (LIMS), Hospital information & management system (HIMS),
OPD Clinics modules, Indoor patients management system, Pharmacy multi platform management
systems & integrated Android and IOS mobile apps.<br/>The integrated modules and the related layouts are designed in a totally customized form against
the exclusive requirements of LIMS, and the deployed modules update the database with the
Real-Time data Posting mechanism assuring transactional accuracy.
        </p>
      </div>
    </div>
    {/* for LIMS */}
    <div className='d-flex flex-column flex-md-row align-items-center juatify-content-center w-100' style={{padding:'1% 10% 5% 10%'}}>
 <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'100%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,}}>
           <div   data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:window.innerWidth>500?'12%':"35%",marginTop:window.innerWidth>500?'-3%':"-10%", borderRadius:"50%",backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>LIMS</h1>
           </div>
           <h6 style={{fontSize:window.innerWidth>500?"150%":"100%"}}>Laboratory
information & Management systems</h6>

           <div className='d-flex flex-column  w-100 mt-3 pb-0 px-3'>
           <p className='text-start px-1'>
           The main operational modules of the software application are for the Laboratory management system.
Having the base parameters of the test profiles, and then patient registration, its workflow starts from Booking, and then onwards the data travels through the modules of Phlebotormy, accessioning, results feed, and signing. 
The application generates graphically rich presented reports of the tests, and so it maintains complete medical records, the functionality also offers its interface for web and mobile apps for the same.
           </p>
           <p className='text-start px-1' data-aos="fade-up" >
          <b>Test Profile </b>Covering the aspects of lab operations, FS Soft environment starts with the detailed module of
configuring the tests, that relate to several other mechanisms and the db parameters such as
delivery schedule, test profile auxiliary tests, the sample types, reference ranges configurations,
and also the inventory used in performing the test.
           </p>
           <p className='text-start px-1' data-aos="fade-up" >
          <b>Pateint's Enrollment </b>The patient's registration module keeps track of medical records in detail, and wherefrom all the
rest of the activity is done in the software.
           </p>
           <p className='text-start px-1' data-aos="fade-up">
          <b>PVC CARD PRINTING Live Capture Of Photograph or Upload Scanned Image </b>Equipped with Barcode or QR Code generating mechanism and print on the card.
           </p>
           <p className='text-start px-1'data-aos="fade-up" >
          <b>Tests (or services)
Registration and Booking </b>Through a conventional shape interface where the user does invoice compilation, the system
takes  feeds  and  initiates  all  the  related  modules  to  further  go  with  the  workflow.
On one side the transactions of sales, accounts, and inventory are posted, while at the same
time, the lab operations, phlebotomy, accessioning, and then eventually the results feeding
modules take on with the task to department-wise deal with the instructions that the system
generated  automatically  on  the  basis  of  the  parameters  that  it  generated. 
           </p>
           <p className='text-start px-1' data-aos="fade-up" >
          <b>Test Results Reporting Module </b>Once the results are fed to the system, an integrated module of LIS take takes the data from the
devices, processes it, and feeds it to the relevant test records of the respective tests. Once the
results are supervised, and digitally signed by the doctor, those become ready for system-
generated printout and digitally available on the web portals and in PDF forms to the email
servers which automatically send them to recipients.
           </p>
           <p className='text-start px-1'data-aos="fade-up" >
          <b>Branded SMS & eMailing Module </b>The Software has Integrated modules for eMailing and to generate Branded SMS notifications. It acknowledges
the patient about the status of the results as well as receipts of any payments.
The email module automatically attaches PDF (of test results) and sends it to the patient in the background
process.
           </p>
           </div>
      </div> 
</div>
    <div className='d-flex flex-column flex-md-row align-items-center juatify-content-center w-100' style={{padding:'0% 10% 0 10%'}}>
     {/* 01 */}
     
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>HIMS</h1>
           </div>
           {/* <h6>PHARMACY POS MODULE</h6> */}
           <h6 Styles={{fontSize:"80%"}}>Hospital information & management system</h6>

           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className='text-start px-1' style={{paddingBottom:window.innerWidth>500?"38%":"5%"}}>
           We have also crafted a hospital information management module in our software application, that deals
with IPD and any hospital services provided to the patients and medical bills compilation aginst the medical
record of the patient registration.
           </p>
           </div>
          
      </div> 
      {/* 02 */}
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>OPD</h1>
           </div>
           <h6>OPD MANAGEMENT SYSTEM</h6>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className='text-start px-1'>
           The System provides the benefits of streamlining of operations, enhanced administration and control,
improved response, cost control and improved profitability. The program can look after Inpatients, OPD
patients, records, database treatments, status illness, and billings. A systematic approach to the way
documents are managed, can transform your Hospital resources to its highest utility and advantage.
           </p>
           </div>
      </div>
      {/* 03 */}
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>PPM</h1>
           </div>
           <h6>PHARMACY POS MODULE</h6>


          
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className='text-start px-1' style={{paddingBottom:window.innerWidth>500?"25%":"5%"}}>
           The real-time Sales Invoicing integrates into the inventory and the accounts mechanics. And
the system generates comprehensive reports of Sales and item-wise detailed stock ledgers and
summary statements.
The said module, very briefly described here, is a complete software package for a pharmacy
business operations setup.
           </p>
           </div>
      </div> 
      
    </div>
    {/* 04 yhan se */}
    <div className='d-flex flex-column flex-md-row align-items-center juatify-content-center w-100' style={{padding:'0% 10% 0 10%',marginTop:"5%"}}>
   {/* 04 */}
    <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>PCS</h1>
           </div>
           <h6>Prescription Compilation System</h6>

           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className='text-start px-1' style={{paddingBottom:window.innerWidth>500?"125%":"5%"}}>
           The Interface of the Prescription Compiling area, makes a complete dashboard for a doctor to have an
access to the information contained by all the modules of the software.
It creates unlimited number of templates of prescription, so that a doctor could have all the required
medicines to prescribe for a disease upon just one click.
Calling up any Patient's name there at, brings all previous records of visits, vitals, medicines prescribed/issued
history, doctor's notes, diagnostic lab tests and the results, ultrasound and any documents in scanned or
PDF formats contained in the system, come in direct access on the same panel screen.
           </p>
           </div>
      </div> 
 {/* 05 */}
  <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>IMS</h1>
           </div>
           <h6>INVENTORY MANAGEMENT MODULE</h6>

           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-start px-1'style={{paddingBottom:"25%"}}>
           The Module for creating POs for Vendors, which will Initiate the Purchase Transaction.
Against the PO the system will compile Final Purchase through which it will Post Inventory
Ledgers and Vendors Accounts and as well as Update the Auxiliary Reporting of the
Area which shall maintain the PO raised and receiving of Inventory against the same.<br></br>The Module of Inventory Management starts from the products enrollment Layout, where it gives
the option to Post the Opening Balance Brought Forward. The given Values update the Stock
ledgers in real time.Receiving of Inventory (Purchase) and further the Outward movement of Items (Consumption or Sales)
all are integrated with Stock Ledgers to generate each Item-wise Detailed Stock Ledger Transaction as
well as each head-wise Summary form of the Report.<br></br>
The Inventory Module has a Click based Dashboard (Index) to show up each Item activities in all the
Modules of the MIS.

           </p>
           </div>
      </div>
     {/* 06 */}
     <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>AS</h1>
           </div>
           <h6>Accounting System</h6>

           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className='text-start px-1' style={{paddingBottom:"0%"}}>
           The system has a complete theme of Accounts and Bookkeeping Module and generates
a very fine analysis of Ledgers and summarized forms of the GL Accounts.
The Chart of Account will be based on 3 Levels of grouping heads (hierarchy).
Each head of the GL Account (Entry control level) falls under the hierarchy of the grouping
heads, formed in the form agreeing to the universal standards.
- Main Group (Predefined) Income, Expense, Assets, and Liability.
- 1st Level of Heads (Predefined)
- 2nd Level Grouping Heads Some will come predefined whereas it has the capacity to accommodate
as per users needs.
- 3rd level (Entry Control ones) Heads (Again there are some which come predefined) rest all are
created/added by the user as per the business needs.
The entry layouts, show up in a very conventional format and easy-to-use theme. The existing modules
and the new ones to make, will all integrate into a powerful engine of Accounts Transactions Posting.
The entries and their respective related account ledger posting will be ensured with maximum levels of
transactional accuracy.
           </p>
           </div>
      </div> 
      
    </div>

















    </div>
  )
}

export default Stratagies