import React,{ useEffect } from 'react';
import './App.css'
import IndexRoutes from './Routes/IndexRoutes';
import HomeScreen from './Screens/Public/HomeScreen';
import ContactScreen from './Screens/Public/ContactScreen';
import Header from './Components/Public/Header';
import {
  BrowserRouter as Router,
  Routes,
  Route,} from "react-router-dom";
import Footer from './Components/Public/Footer'; 

// import Homee from './Components/Public/Home/Homee';
import AOS from 'aos'
function App() {
  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    // alert('This is app js')
  },[])
  return (
    <>
    {/* <h1>index</h1> */}
    {/* <IndexRoutes></IndexRoutes> */}
    {/* <HomeScreen></HomeScreen> */}
    <Router>
     {/* <Header>

     </Header> */}
     <Routes>
      <Route exact path='/' element={<HomeScreen></HomeScreen>}></Route>
      <Route path='/Shope' element={<HomeScreen></HomeScreen>}></Route>
      <Route path='/Contact' element={<ContactScreen></ContactScreen>}></Route>
      <Route path='/Login' element={<HomeScreen></HomeScreen>}></Route>
     </Routes>
     <Footer></Footer>
    </Router>  
    </>
  );
}

export default App;
