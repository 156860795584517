import React from 'react'
import Header from '../Components/Public/Header';
import DashboardScreen from '../Screens/User/DashboardScreen';
import {
    BrowserRouter as Router,
    Routes,
    Route,} from "react-router-dom";
function UserRoutes() {
  return (
    <Router>
    <Header></Header>
     <Routes>
      <Route path='/' element={<DashboardScreen childType="Home"></DashboardScreen>}></Route>
      <Route path='/Shope' element={<DashboardScreen childType="Shope"></DashboardScreen>}></Route>
      <Route path='/Contact' element={<DashboardScreen childType="Contact"></DashboardScreen>}></Route>
      <Route path='/Login' element={<DashboardScreen childType="Login"></DashboardScreen>}></Route>
     </Routes>
    </Router>  
  )
}

export default UserRoutes