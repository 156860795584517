import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLogin: false,
  userJWTToken:'',
  userRole:'public'
}

export const authenticateUse = createSlice({
  name: 'UserAuth',
  initialState:initialState,
  reducers: {
    Login: (state) => {
      state.isLogin = true;
      state.userRole='user';
    },
    Logout: (state) => {
      state.isLogin = false
      state.userRole='public';
    }
  },
})

// Action creators are generated for each case reducer function
export const { Login,Logout } = authenticateUse.actions

export default authenticateUse.reducer