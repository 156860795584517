import React from 'react'

const ContactScreen=()=> {
  return (
    <div className='container-fluid m-0 p-0'>
       <div className='row m-0 p-0'>
               <h1>Contact Screen</h1>
       </div>
    </div>
  )
}

export default ContactScreen