import React from 'react'
import Brand from '../../../Assets/Images/Branding.jpg'
import Styles from '../../../Assets/Styles/Style'
import Logo from "../../../Assets/Images/Logo/FS Soft LogoFull.png"

import Aos from 'aos'
import "aos/dist/aos.css"

const Branding=()=> {
  return (
    <div className='container-fluid m-0 p-0'>
     
           <div className='row m-0 p-0' style={{backgroundImage:`url(${Brand})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'100vh'}}>
       <div className='col-12 m-0 p-0  position-relative'>
        {/* For web view Start */}
         <div class="shadow py-5 px-4 bg-light d-none d-md-block animate__animated animate__zoomIn animate__delay-2s" style={{ backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,position:'absolute',left:'10%',bottom:'8%',width:'35%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius}}>
            {/* <h1 className='fw-bold mt-2 mb-3' 
                style={{fontSize:Styles.brandHeading}}>
             FS Soft
            </h1> */}
    <img className='img-fluid' style={{marginLeft:"30%", width:'150px',height:'150px'}} src={Logo} alt=''></img>

            <p className='mb-3 mt-2' style={{fontSize:Styles.brandDiscripition,textAlign:'justify'}}>
            FS Soft a dynamic Healthcare IT solution.
            </p>
            {/* 10-may-2023 */}
            {/* <p style={{fontsize:Styles.brandText}}>Some text place here</p>
          <button className='w-50 p-3 border-0 m-0 mb-2' 
                  style={{
                  backgroundColor:Styles.brandReadMoreBtnColor,
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize
                  }}>Read More</button> */}
         </div>
        {/* For web view End */}
        {/* For Mobile view Start*/}
        <div class="shadow py-5 px-4 d-block  d-md-none position-absolute  top-50 start-50 translate-middle" style={{ backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'85%',borderRadius:'15px'}}>
            {/* <h1 className='fw-bold mt-2 mb-3' style={{fontSize:Styles.mobilebrandHeading}}>
            FS Soft
            </h1> */}
             <img className='img-fluid' style={{marginLeft:"20%", width:'150px',height:'150px'}} src={Logo} alt=''></img>

<p className='mb-3 mt-2' style={{fontSize:Styles.brandDiscripition,textAlign:'justify'}}>
FS Soft a dynamic Healthcare IT solution.
</p>
            {/* <p className='mb-3 ' style={{fontSize:Styles.brandDiscripition,textAlign:'justify'}}>
            
            Future Scientific is providing superior quality laboratory equipments and test kits to its customers in Pakistan

            </p> */}
            {/* <p style={{fontsize:Styles.brandText}}>Some text place here</p> */}
          {/* <button className='w-50 p-3 border-0 m-0 mb-2' 
                  style={{
                  backgroundColor:Styles.brandReadMoreBtnColor,
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.mobilebuttonbrandHeading,
                  }}>Read More</button> */}
         </div>
        {/* For mobile view End */}
       </div>
      </div>

    </div>
  )
}

export default Branding