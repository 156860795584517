const themeStyle ={
    themeColorLight:'white',
    themeColorDark:'black',
    themeBoxeBorderRadius:'20px',
    themeSmallBoxeBorderRadius:'50%',
    themeCommonColor:'#ffc601'

}
const siteStyle ={
    brandHeading:'3rem',
    brandDiscripition:'1.45rem',
    brandText:'1rem',
    brandReadMoreBtnTextSize:'1.35rem',
    brandReadMoreBtnRadius:'15px',
    brandReadMoreBtnColor:'#ffc601',
    ReadMoreBtnTextColor:themeStyle,
    mobilebrandHeading:'2rem',
    mobilebuttonbrandHeading:'1rem',
    siteStrategiesBackgroundColor:'#e5e5e5',
    strategyHeading:'3.75rem'
}
export default siteStyle;