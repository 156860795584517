
import React from 'react'
import Styles from '../../../Assets/Styles/Style'
import Consult from '../../../Assets/Images/Consulting.png'
function Consulting() {
  return (

 <div className='container-fluid m-0 p-0 consultingMainRow' style={{minHeight:'85vh',backgroundColor:Styles.brandReadMoreBtnColor}} >
  <div className='row m-0 p-0' style={{height:'100vh',backgroundColor:Styles.brandReadMoreBtnColor}}>
  <div className='col-12 col-md-5 d-flex flex-column justify-content-center px-5'>
     <h1>The Stretch Consultancy</h1>
     <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
     <p> Images from  </p>
     <button style={{ borderRadius: Styles.brandReadMoreBtnRadius , color:Styles.ReadMoreBtnTextColor.themeColorDark , backgroundColor:'transparent'  }} className='  mb-2 py-3 w-md-50 w-75  fw-bold fs-5 mobilebuttonbrandHeading ' > LEARN MORE </button>
   </div>
   <div className='m-0 p-0 col-7 d-none d-md-block'>
      <img className='img-fluid m-0 p-0' src={Consult} style={{height:'80vh'}}></img>
   </div>
  </div>
  </div>
  )
}

export default Consulting