import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Header from '../Header'
import Branding           from './Branding'
import Stratagies         from './Stratagies'
import About              from './About'
import Team               from './Team'
import Consulting         from './Consulting'
import LeadreAdvice       from './LeadreAdvice'
import Contact            from './Contact'
import ContactSend from './ContactSend'
import BackgroundParticles from './BackgroundParticles'
function Homee() {
  //for scroll handler
  const [scroll,setscroll]=useState()
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    }, []);
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // => scroll position
      console.log(scrollPosition);
      setscroll(scrollPosition)
  };
  const handler=(value)=>{
    // alert("hy"+value)
    handleClickScroll(value)
  }
  // scroll end
  const handleClickScroll = (value) => {
    var element=""
    if(value=="AboutUs")
   { element = document.getElementById('AboutUs');}
   else if(value=="WhatWeDo"){
    element = document.getElementById('WhatWeDo');
   }
   else if(value=="ContactUs"){
    element = document.getElementById('ContactUs');
   }

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className='container-fluid m-0 p-0'>
      {/* {window.pageYOffset>300&& */}
      {/* <div style={{height:"25%",width:"5%",backgroundColor:"black",position:"absolute"}}>top</div> */}
     {/* {scroll>300&&
     <button
     onClick={() => {
       window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     }}
     style={{
       position: 'fixed',
       padding: '1rem 1rem',
       fontSize: '20px',
       bottom: '40px',
       right: '40px',
       backgroundColor: 'black',
       color: '#fff',
       textAlign: 'center',
     }}
   >
     Top
   </button>} */}
      
    {/* } */}
       
       {/* <div className="container-fluid" onClick={()=>{
        alert("hy")
       }}>
        helloo
       <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-none d-md-inline-flex flex-row" style={{cursor:'pointer'}}>
        <li className="nav-item mx-2">
          <Link to={'http://futurescientific.com.pk/'} className="nav-link active" aria-current="page" >Home</Link>
        </li>
        </ul>
       </div> */}
      <Header handler={handler} ></Header>
       {/* <BackgroundParticles></BackgroundParticles> */}
       <Branding></Branding>
       <div id="AboutUs">
       <Stratagies></Stratagies> 
       </div>       
       <div id="WhatWeDo">

       <About ></About>   
       </div>           
       {/* <Team></Team>                */}
       {/* <Consulting></Consulting>          */}
       {/* <LeadreAdvice></LeadreAdvice>        */}
       {/* <Contact></Contact> */}
       <div id="ContactUs">
       <ContactSend></ContactSend>            
      </div>
    </div>
  )
}

export default Homee