import React from 'react'

const Footer=()=> {
  return (
    <div className='container-fluid m-0 p-0'
    //  style={{minHeight:'100vh',backgroundOrigin:'border-box',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url(${FooterBg})`}}
      >
    </div>
  )
}

export default Footer