import React from 'react'
import Styles from '../../../Assets/Styles/Style'
import Team from '../../../Assets/Images/Team.jpg'
import TeamLow from '../../../Assets/Images/TeamLowePart.jpg'
import DA from '../../../Assets/Images/ReportingModule/DesktopPic.jpg'
import WA from '../../../Assets/Images/ReportingModule/webApplication.jpg'
import AP from '../../../Assets/Images/ReportingModule/androidApp.jpg'
import IP from '../../../Assets/Images/ReportingModule/iosApp.jpg'
import SS from '../../../Assets/Images/ReportingModule/server.jpg'
import b6 from '../../../Assets/Images/Ecommerce.png'
const About=()=> {
  return (
    <div className='container-fluid m-0 p-0 pb-5 ' style={{background:"#e5e5e5"}}>
       <div className='col-12 m-0 p-0  d-flex justify-content-center pt-5'  
      // style={{backgroundImage:`url(${Team})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'85vh'}}
      >   
        {/* <h1 style={{fontSize:Styles.strategyHeading,}} className='fw-bolder' >What We Do</h1> */}
      
      </div>
      <div className='d-flex flex-column flex-md-row py-2 py-md-5 '>
      <div data-aos={"fade-up"} className=' w-50 d-flex align-item-center justify-content-center stratigy2 '>
        <h6 style={{fontSize:"200%", marginLeft:'5%',marginTop:"4%"}} className='fw-bolder' >Reporting Module</h6>
      </div>
      <div data-aos={"fade-up"} className='w-50 stratigy2'>
        <p className='stratigy2text' style={{fontSize:Styles.brandText ,marginRight:'25%' }}>
        FS Soft generates reports from each module being deployed as an integrated application. Apart from the
detailed transactions belonging to their respective modules, it also compiles comprehensive summarized
reporting involving multiple modules to show up on one page.
        </p>
      </div>
    </div>
   
    <div className='row m-0 p-0'>
    {/* <div className='col-12 m-0 p-0' style={{backgroundImage:`url(${Team})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'85vh'}}>    */}
      
     
      {/* <div className='col-12 d-flex justify-content-center mainAboutBox' style={{backgroundImage:`url(${TeamLow})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'60vh'}}> */}
      <div className='col-12 d-flex justify-content-center mainAboutBox'
      //  style={{backgroundImage:`url(${TeamLow})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'60vh'}}
       >
         {/*boxes div  */}
         <div className='w-75 d-flex flex-row' style={{marginTop:'2%'}}>
         <div className='col-12'>
             <div className='row m-0 p-0 d-flex flex-row gap-3' >
             {/* 1 */}
             <div  data-aos="fade-up"className='m-0 px-1 py-4 shadow businessBox mt-5' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0' 
              // style={{cursor:"pointer"}} onClick={()=>{
              //         window.open('https://mpl-labs.pk')
                      

              // }}
              >
                {/* <div className='col-12 col-md-4 m-0 p-0' >
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b1}`}></img>
                </div>
                </div> */}
                <div className='col-12 col-md-12 m-0 p-0 aboutText '>
                <div className='pb-2  d-flex justify-content-center' style={{paddingTop:window.innerWidth>500?"0%":"8%"}}>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' style={{height:window.innerWidth>500?"100px":"50px",width:window.innerWidth>500?"100px":"50px"}} src={`${DA}`}></img>

                </div>

                    <h5 className='mb-2 ' style={{marginLeft:"4%",paddingTop:"6%"}}>DESKTOP APPLICTION</h5>
                    <p style={{marginLeft:"4%"}}>This version includes all the modules and their respective functions that are
included  to  form  the  complete  solution  design  of  the  main  Server.
Majorly  the  administrative  usage  of  the  application  included  access
management, data handling as admin (having maximum access), and
meticulous reporting side.</p>
                </div>
                <div className='col-12 m-0 p-0 '>
                    {/* <a href='https://www.mpl-labs.pk/'>Click here</a> */}
                    
                   
                </div>
              </div>
            </div>
             {/* 2 */}
             <div data-aos="fade-up"className='m-0 px-1 py-4 mt-5 shadow businessBox' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'
              //   style={{cursor:"pointer"}} onClick={()=>{
              //         window.open('https://mpl-labs.pk')
              //  }}
               >
                {/* <div className='col-12 col-md-4 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b2}`}></img>
                </div>
                </div> */}
             
                <div className='col-12 col-md-12 m-0 p-0 aboutText'>
                <div className='pb-2 d-flex justify-content-center'  style={{paddingTop:window.innerWidth>500?"0%":"8%"}}>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' style={{height:window.innerWidth>500?"100px":"50px",width:window.innerWidth>500?"100px":"50px"}} src={`${WA}`}></img>

                </div>
                    <h5 className='mb-2 ' style={{marginLeft:"4%",paddingTop:"6%"}}>WEB APPLICTION</h5>
                    <p style={{marginLeft:"4%"}}>
                    The browser-based web application serves the modules that we include to
develop under that category. The complete db structure includes a huge
amount of database tables and their relevant functionality, but we will
undertake to develop the modules which are indispensable to be provided
in that form.
</p>
              
                </div>
              </div>
            </div> 
            {/* 3 */}
            <div data-aos="fade-up" className='m-0 px-1 py-4 shadow mt-5 businessBox' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'>
                {/* <div className='col-12 col-md-4 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b3}`}></img>
                </div>
                </div> */}
                <div className='col-12 col-md-12 m-0 p-0 aboutText'>
                <div className='pb-2  d-flex justify-content-center'  style={{paddingTop:window.innerWidth>500?"0%":"8%"}}>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' style={{height:window.innerWidth>500?"100px":"50px",width:window.innerWidth>500?"100px":"50px"}} src={`${AP}`}></img>

                </div>
                <h5 className='mb-2 ' style={{marginLeft:"4%",paddingTop:"6%"}}>ANDROID APPLICATION</h5>
                    <p style={{marginLeft:"4%"}}>
                    In addition to having all the features of the web application version, the
mobile application comes for public usage as well as it provides some
features for the admin/panel user.
Mostly it covers the area of general user needs, such as reports history,
appointment booking for oneself, and reports viewing and giving feedback,
etc.
</p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div data-aos="fade-up" className='m-0 px-1 py-4 shadow businessBox mt-5' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'>
                {/* <div className='col-12 col-md-4 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b4}`}></img>
                </div>
                </div> */}
                <div className='col-12 col-md-12 m-0 p-0 aboutText'>
                <div className='pb-2  d-flex justify-content-center'  style={{paddingTop:window.innerWidth>500?"0%":"8%"}}>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' style={{height:window.innerWidth>500?"100px":"50px",width:window.innerWidth>500?"100px":"50px"}} src={`${IP}`}></img>

                </div>
                <h5 className='mb-2 ' style={{marginLeft:"4%",paddingTop:"6%"}}>iOS APPLICATION</h5>
                    <p style={{marginLeft:"4%"}}>
                    FS Soft offers the same mobile application for Android systems for iOS users.
</p>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div data-aos="fade-up"className='m-0 px-1 py-4 shadow businessBox mt-5' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'>
                {/* <div className='col-12 col-md-4 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b5}`}></img>
                </div>
                </div> */}
                <div className='col-12 col-md- m-0 p-0 aboutText '>
                <div className='pb-2  d-flex justify-content-center'  style={{paddingTop:window.innerWidth>500?"0%":"8%"}}>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' style={{height:window.innerWidth>500?"100px":"50px",width:window.innerWidth>500?"100px":"50px"}} src={`${SS}`}></img>

                </div>
                <h5 className='mb-2  ' style={{marginLeft:"4%",paddingTop:"6%"}}>SERVER SIDE</h5>
                    <p style={{marginLeft:"4%"}}>
                    Application Structure, Database Design, and business logic including APIs
for Web and Mobile App. Interfaces
</p>
                </div>
              </div>
            </div>
            {/* 6 */}
            {/* <div className='m-0 px-1 py-4 shadow businessBox' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'>
                <div className='col-12 col-md-4 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b6}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-8 m-0 p-0 aboutText'>
                    <h5 className='mb-2'>Ecommerce</h5>
                    <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                </div>
              </div>
            </div> */}
            {/* 7 */}
          

             </div>
         </div>
          
         </div>
      </div>
    </div>
    </div>
  )
}

export default About